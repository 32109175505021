<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="名称/手机/邮箱/地址" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model="params.goods" placeholder="内部单号/平台单号/快递单号/产品" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select style="width: 100%;" placeholder="请选择标签" v-model="params.tags" :remote-method="remoteMethod" :loading="tags_loading" :multiple-limit="5" remote multiple filterable default-first-option>
						<el-option v-for="(tag, t) in tags" :key="t" :label="tag" :value="tag"></el-option>
					</el-select>
				</el-form-item>
				<!-- 当角色只能看到自己客户时，此项返回数据为空，可不需要显示 -->
				<el-form-item>
					<el-select v-model="params.former_id" placeholder="前业务" filterable multiple collapse-tags>
						<el-option v-for="staff in staffs" :key="staff.id" :label="staff.name" :value="staff.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.staff_id" placeholder="现业务" filterable multiple collapse-tags>
						<el-option v-for="staff in staffs" :key="staff.id" :label="staff.name" :value="staff.id"></el-option>
					</el-select>
				</el-form-item>
				<template v-if="admin_user.shop_id">
					<!-- 当角色只能看到自己客户时，此项返回数据为空，可不需要显示 -->
					<el-form-item>
						<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
					</el-form-item>
				</template>
				<el-form-item v-else>
					<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.source" placeholder="请选择渠道来源" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="(source, s) in fields.sources" :key="s" :label="source" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.o_source" placeholder="请选择订单来源" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="(source, s) in order_fields.sources" :key="s" :label="source" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.c_source" placeholder="请选择线索来源" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="(source, s) in sources" :key="s" :label="source" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.status" placeholder="请选择状态" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="(status, s) in fields.statuses" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.grade" placeholder="请选择等级" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="(grade, g) in fields.grades" :key="g" :label="grade" :value="g"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.scale" placeholder="请选择规模" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="(scale, s) in fields.scales" :key="s" :label="scale" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-cascader style="width: 100%;" placeholder="请选择所属地区" v-model="params.regions" :options="tree_regions" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable>
						<el-option label="创建时间" value="created"></el-option>
						<el-option label="更新时间" value="updated"></el-option>
					</el-select>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getClients({ ...params, page: 1})">查询</el-button>
					<!-- <el-button type="primary" @click="changsha">查询</el-button> -->
				</el-form-item>
			</el-form>
			<el-table slot="table" ref="clients_table" class="scroll-wrapper absolute" :data="tables" height="100%" :size="theme.size">
				<el-table-column type="selection" width="42"></el-table-column>
				<el-table-column label="客户信息" min-width="120">
					<template slot-scope="scope">
						<el-link type="primary" @click="$refs['show_client'].open({id: scope.row.id})"><i class="el-icon-user"></i> {{scope.row.name}}</el-link>
						<p v-if="scope.row.mobile"><i class="el-icon-mobile-phone"></i> {{scope.row.mobile}}</p>
						<!-- <p v-if="scope.row.email"><i class="el-icon-message"></i> {{scope.row.email}}</p> -->
						<p v-if="scope.row.address"><i class="el-icon-map-location"></i> {{scope.row | lastCity}}</p>
						<p v-if="scope.row.source_model_type == 'App\\Models\\Clue' && scope.row.source_model">{{scope.row.source_model.remarks}}</p>
					</template>
				</el-table-column>
				<el-table-column label="公司信息" min-width="180" v-if="theme.width >= 1200">
					<template slot-scope="scope">
						<p><i class="el-icon-office-building" v-if="scope.row.corporation"></i> {{scope.row.corporation}}</p>
						<p v-if="scope.row.scale"><i class="el-icon-coin"></i> {{fields.scales[scope.row.scale]}} <template v-if="scope.row.share">占比 {{scope.row.share}} %</template></p>
						<p>
							<el-tag size="mini" style="margin-right: 10px;" v-for="tag in scope.row.tags" :key="tag.id">{{tag.name}}</el-tag>
						</p>
					</template>
				</el-table-column>
				<el-table-column label="销售业务" min-width="120">
					<template slot-scope="scope">
						<p v-if="scope.row.employee"><i class="el-icon-suitcase"></i> {{scope.row.employee.name}}</p>
						<p v-if="scope.row.former" class="employee-former"><i class="el-icon-suitcase"></i> {{scope.row.former.name}}</p>
						<!-- 目前只有订单来源 -->
						<!-- <p v-if="scope.row.source_model"><i class="el-icon-link"></i> 来源于 {{sources[scope.row.source_model_type]}}</p> -->
						<el-dropdown size="small" @command="updClient">
							<el-tag size="mini" :type="tag_colors[scope.row.status] || '未知'">{{fields.statuses[scope.row.status] || '未知'}}</el-tag>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="(status, s) in fields.statuses" :key="s" :command="{...scope.row, status: s}">{{status}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						-
						<el-dropdown size="small" @command="updClient">
							<span style="font-size: 12px;">{{fields.grades[scope.row.grade] || '未知'}}</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="(grade, g) in fields.grades" :key="g" :command="{...scope.row, grade: g}">{{grade}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</el-table-column>
				<el-table-column label="最后跟进" min-width="210">
					<template slot-scope="scope">
						<el-input
							ref="followInput"
							rows="3"
							size="mini"
							type="textarea"
							maxlength="255"
							placeholder="请输入本次跟进记录备注说明"
							v-model="following.comment"
							v-if="following.client_id === scope.row.id"
							@keyup.enter.native="saveFollow"
							@blur="saveFollow"
							show-word-limit
							clearable>
						</el-input>
						<template v-else>
							<div v-if="scope.row.latest_follow" @click="inputFollow(scope.row.latest_follow, scope.row.employee_id)">
								<p>{{follow_fields.types[scope.row.latest_follow.type] || '未知操作'}}于 {{scope.row.latest_follow.created_at}}</p>
								<p style="font-size: 12px; color: rgb(153, 153, 153); line-height: 1.2; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{scope.row.latest_follow.comment}}</p>
								<p style="font-size: 12px; color: rgb(183, 160, 106); line-height: 1.2; font-weight: bold;" v-if="scope.row.latest_follow.course">{{follow_fields.courses[scope.row.latest_follow.course]}}，{{follow_fields.learns[scope.row.latest_follow.learn]}}</p>
							</div>
							<p @click="inputFollow({comment: '', client_id: scope.row.id})" v-else>暂无～</p>
						</template>
					</template>
				</el-table-column>
				<el-table-column label="最新订单" min-width="300">
					<template slot-scope="scope">
						<div v-if="scope.row.latest_order">
							<!-- <p>{{scope.row.latest_order.no}} {{ostatuses[scope.row.latest_order.status]}} {{scope.row.latest_order.paid_price/100}} 元 <template v-if="scope.row.latest_order.entered_at">下单于 {{scope.row.latest_order.entered_at}}</template> <template v-if="scope.row.latest_order.express_no">快递单号 {{scope.row.latest_order.express_no}}</template></p> -->
							<p>{{scope.row.latest_order.paid_price/100}} 元 {{scope.row.latest_order.entered_at || scope.row.latest_order.created_at}} 下单于 {{order_fields.sources[scope.row.latest_order.source]}} <template v-if="scope.row.latest_order.express_no"> {{expresses[scope.row.latest_order.express] || '未知'}}单号 {{scope.row.latest_order.express_no}}</template> {{order_fields.statuses[scope.row.latest_order.status]}}</p>
							<p style="color: #999; line-height: 1.2; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" v-for="goods in scope.row.latest_order.order_goods" :key="goods.id">{{goods.title}}</p>
						</div>
						<p v-else>暂无～</p>
					</template>
				</el-table-column>
				<el-table-column label="操作时间" min-width="200" v-if="theme.width >= 1240">
					<template slot-scope="scope">
						<p>更新：{{scope.row.updated_at}}</p>
						<p>创建：{{scope.row.created_at}}</p>
					</template>
				</el-table-column>
				<el-table-column label="更多" width="60">
					<el-dropdown slot="header" @command="batchAction">
						<el-button type="text">操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="create" :disabled="!$utils.create($api.URI_CLIENTS)">添加客户</el-dropdown-item>
							<el-dropdown-item command="change" :disabled="!$utils.checkAuth($api.URI_CLIENTS, 'transfer')">转移选中</el-dropdown-item>
							<el-dropdown-item command="sendoa">发送OA</el-dropdown-item>
							<el-dropdown-item command="export">导出选中</el-dropdown-item>
							<el-dropdown-item command="delete">删除选中</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<template slot-scope="scope">
						<el-button type="text" :disabled="!$utils.restore($api.URI_CLIENTS)" @click="onRestore(scope.row)" v-if="scope.row.deleted_at">恢复</el-button>
						<el-button type="text" :disabled="!$utils.delete($api.URI_CLIENTS)" @click="onDelete(scope.row)" v-else>删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page.sync="params.page"
				:total="params.total"
				@size-change="(v) => { getClients({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getClients({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
		<el-dialog width="480px" title="转移客户" :visible.sync="transfer_dialog">
			<div v-if="transfering && transfer.clients">
				<h3>正在转移客户 {{transfer_current}}/{{transfer.clients.length}}</h3>
				<el-progress :percentage="Math.round((transfer_current/transfer.clients.length)*100)"></el-progress>
			</div>
			<el-form ref="transfer" label-width="80px" :model="transfer" :size="theme.size" v-else status-icon>
				<el-form-item label="转移数量" v-if="transfer.clients">{{transfer.clients.length}} 条客户</el-form-item>
				<el-form-item label="业务专员" prop="staff_id" :rules="[{ required: true, message: '请选择目标业务员', trigger: ['blur', 'change'] }, { validator: checkTransfer, trigger: ['blur', 'change'] }]">
					<el-select v-model="transfer.staff_id" placeholder="业务员" filterable clearable>
						<el-option v-for="staff in staffs" :key="staff.id" :label="staff.name" :value="staff.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="更新状态">
					<el-switch v-model="transfer.keep_state" active-value="1" inactive-value="0" active-text="保留状态" inactive-text="待跟进"></el-switch>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('transfer')">提交转移</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<show-client ref="show_client" :table-temp="table_temp" @download="downloadTemp" @refresh="getClients(params)"></show-client>
	</div>
</template>

<style>
	.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
		display: none;
	}
	.employee-former {
		color: #ccc; text-decoration: line-through;
	}
	.employee-former .el-icon-suitcase:before {
		content: '前';
		color: #ccc; text-decoration: line-through;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import showClient from './show';
	import mainTable from '@/components/main-table';
	import { ad_export_json_to_excel_multi_sheet } from '@/utils/exportExcel';
	// import mIndex from './m_index';
	export default {
		components: {
			// mIndex,
			mainTable,
			showClient
		},
		computed: {
			...mapState(['theme', 'regions', 'admin_user', 'oa_departments', 'models']),
			tree_regions () {
				return this.$utils.buildTree(this.regions);
			},
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			},
			fields () {
				return this.models.clients || {};
			},
			order_fields () {
				return this.models.orders || {};
			},
			follow_fields () {
				return this.models.client_follows || {};
			},
			sources () {
				if (!this.models) return [];
				const { clues } = this.models;
				return clues.sources || [];
			},
			tag_colors () {
				return {
					'pending': 'info',
					'uncontact': 'warning',
					'following': 'warning',
					'leaning': 'warning',
					'added': 'success',
					'dealed': 'success',
					'intention': 'success',
					'invalided': 'danger'
				};
			},
			table_temp () {
				const thead = ['名称', '手机', '邮箱', '地区ID', '标签'];
				const filter = ['name', 'mobile', 'email', 'region', 'tags'];
				if (!this.admin_user.shop_id) {
					thead.unshift('站点ID');
					filter.unshift('shop_id');
				}
				return {
					data: [],
					sheet: '客户列表',
					thead,
					filter
				}
			}
		},
		filters: {
			lastCity ({address}) {
				if (!address) return '';
				// return address.substring(0, address.lastIndexOf("市")+1);
				return address.substring(0, 6);
			}
		},
		methods: {
			msgError (msg) {
				const { is_mobile } = this.theme;
				return is_mobile ? this.$toast.fail(msg) :  this.$message.error(msg);
			},
			msgSuccess (msg) {
				const { is_mobile } = this.theme;
				return is_mobile ? this.$toast.success(msg) :  this.$message.success(msg);
			},
			downloadTemp () {
				const regions = {};
				this.regions.forEach((r) => {
					regions[r.id] = r.name;
					r.parent_name = regions[r.parent_id]; 
				});
				const template = [
					{
						filter: ['id', 'name', 'parent_name'],
						thead: ['ID', '名称', '上级'],
						sheet: '地区ID',
						data: this.regions
					}
				];
				if (!this.admin_user.shop_id) {
					template.push({
						filter: ['id', 'name'],
						thead: ['ID', '名称'],
						sheet: '站点ID',
						data: this.shops
					});
				}
				template.unshift(this.table_temp);
				ad_export_json_to_excel_multi_sheet(template, '客户导入模板_生成于' + this.$moment().format('YYYYMMDD'));
			},
			async remoteMethod (query) {
				if (query !== '') {
					this.tags_loading = true;
					const res = await this.$http.get(this.$api.URI_TAGS, { params: {action: 'search', word: query} });
					this.tags = res.data.result.map((tag) => { return tag.name; });
					this.tags_loading = false;
				} else {
					this.tags = [];
				}
			},
			onRestore (r) {
				this.$confirm(r.name, '确定要恢复客户吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.handleClients('restore', r.id);
				});
			},
			onDelete (r) {
				this.$confirm(r.name, '确定要删除客户吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.handleClients('delete', r.id);
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'transfer':
							var { clients, staff_id, keep_state } = this.transfer;
							for (let client of clients) {
								if (client.user_id === staff_id) return this.$message.error(client.name+" 当前业务已经是 "+client.user.name);
							}
							this.submitClient({action: 'transfer', staff_id, keep_state, clients: clients.map((c) => { return c.id; })}, () => {
								this.getClients(this.params);
								this.transfer_dialog = false;
							});
						break;
					}
				});
			},
			updClient (c) {
				// 删除没必要信息
				delete c.client_follows;
				delete c.source_model;
				delete c.employee;
				delete c.regions;
				delete c.orders;
				delete c.shop;
				delete c.tags;
				this.submitClient(c, () => {
					this.getClients(this.params);
				});
			},
			inputFollow (r, i = 0) {
				this.following = { comment: '', client_id: r.client_id };
				if (r.created_at && this.$moment().isSame(r.created_at, 'd') && r.employee_id == i) {
					this.following = { ...r };
				}
				this.$nextTick(() => {
					this.$refs.followInput.focus();
				});
			},
			saveFollow () {
				const { comment } = this.following;
				if (comment) {
					this.submitFollow(this.following, () => {
						this.getClients(this.params);
					});
				}
				this.following = {
					client_id: 0,
					comment: ''
				};
			},
			async submitClient (data, onClose) {
				const res = await this.$http.post(this.$api.URI_CLIENTS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose
				});
			},
			async submitFollow (data, onClose) {
				const res = await this.$http.post(this.$api.URI_CLIENTS_FOLLOWS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose
				})
			},
			async batchAction (m) {
				if (m === 'create') return this.$refs['show_client'].open();
				const { selection } = this.$refs.clients_table;
				if (!selection.length) return this.$message.error('请选择操作内容');
				switch (m) {
					case 'change':
						this.transfer_dialog = true;
						this.transfer = { clients: selection };
						break;
					case 'export':
							selection.forEach((c) => {
								c.f_regions = c.regions.map((r) => { return r.name; });
								c.f_tags = c.tags.map((r) => { return r.name; });
							});
							this.$confirm(`此操作将导出${selection.length}条内容, 是否继续？`, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								var file = "客户导出" + this.$moment().format('YYYYMMDDHHmmss');
								var header = [['ID', '名称', '手机', '邮箱', '地区', '标签', '更新时间', '创建时间']];
								var filter = ['id', 'name',  'mobile', 'email', 'f_regions', 'f_tags', 'updated_at', 'created_at'];
								this.$utils.handleDownload(header, filter, selection, file, "客户列表");
							})
						break;
					case 'delete':
							this.$confirm(`此操作将永久删除${selection.length}条内容, 是否继续？`, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(async () => {
								let index = 0;
								let notify = this.$notify({
									title: '提示',
									dangerouslyUseHTMLString: true,
									message: '<p>正在准备删除...</p>',
									showClose: false,
									duration: 0
								});
								for (var o in selection) {
									let { id } = selection[o];
									index += 1;
									notify.message = '<p>正在删除 ' + index + ' / ' + selection.length + '</p>';
									await this.$http.get(this.$api.URI_CLIENTS, {params: {action: "delete", id}, headers: {loading: true}});
								}
								if (index === selection.length) {
									notify.message = '<p>删除完成！</p>';
									setTimeout(() => {
										notify.close();
										this.getClients(this.params);
									}, 3000);
								}
							});
						break;
					case 'sendoa':
						var clients = [];
						selection.forEach((c) => {
							clients.push({
								name: c.name,
								mobile: c.mobile,
								regions: c.address ? c.address.substring(0, 6) : '无地址信息',
								// regions: c.regions.map((r) => { return r.name; }).join('')
							});
						});
						this.submitClient({action: 'sendoa', clients});
						break;
				}
			},
			async handleClients (action, id) {
				const res = await this.$http.get(this.$api.URI_CLIENTS, {params: { action, id }, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getClients(this.params);
				this.msgSuccess(msg);
			},
			// async changsha () {
			// 	const res = await this.$http.get(this.$api.URI_CLIENTS, {params: { action: 'changsha' }, headers: {loading: true}});
			// 	const { result } = res.data;
			// 	var file = "客户导出" + this.$moment().format('YYYYMMDDHHmmss');
			// 	var header = [['名称', '手机', '订单', '商品', '金额']];
			// 	var filter = ['name',  'mobile', 'no', 'title', 'shop_price'];
			// 	this.$utils.handleDownload(header, filter, result, file, "客户列表");
			// },
			async getClients (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CLIENTS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.tables = result.data;
				this.shops = result.shops;
				this.staffs = result.staffs;
				this.companies = result.companies;
				this.expresses = result.expresses;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			checkTransfer (rule, value, callback) {
				var { clients, staff_id } = this.transfer;
				for (let client of clients) {
					if (client.user_id === staff_id) return callback(new Error(client.name+" 当前业务已经是 "+client.user.name));
				}
				return callback();
			}
		},
		data() {
			return {
				tags: [],
				shops: [],
				staffs: [],
				companies: [],
				expresses: [],
				tables: [],
				client: {},
				company: {},
				transfer: {},
				following: {
					client_id: 0,
					comment: ''
				},
				transfer_current: 0,
				tags_loading: false,
				transfering: false,
				transfer_dialog: false,
				params: {
					date_type: 'created',
					perPage: 10
				}
			}
		},
		async created () {
			this.getClients(this.params, true);
		}
	};
</script>